.App {
  position: relative; /* Ensure .App is positioned relative */
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  align-items: center;
  overflow: hidden; /* Prevent scrolling */
}

.background-container {
  position: fixed; /* Fix the background in place */
  width: 100%;
  height: 100vh; /* Full viewport height */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat;
  z-index: -1; /* Ensure it is behind other content */
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1; /* Ensure content is above the background */
  width: 100%;
  height: 100%;
}

.icons-bar {
  position: absolute; /* Position the icons bar absolutely */
  bottom: 0; /* Move it to the bottom of the screen */
  width: 100%; /* Ensure it spans the full width */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Add some spacing from the bottom */
}

.icon {
  margin: 0 10px; /* Add some spacing between icons */
}

.icon-image {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  transition: transform 0.3s ease; /* Add transition for smooth scaling */
}

.icon-image:hover {
  transform: scale(1.2); /* Scale up the icon on hover */
}

.contract-address {
  position: absolute; /* Position the contract address absolutely */
  bottom: 120px;
  left: 40vw;
  color: rgb(255, 222, 35);
  font-size: 1em;
  background-color: rgba(0, 0, 0, 0.774);
  padding: 5px 10px;
  border-radius: 5px;
}

.copied-message {
  position: absolute;
  bottom: 140px; /* Position the message above the contract address */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(5, 5, 5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8em;
  text-shadow: none; /* Remove text shadow for better readability */
}

.App-footer {
  font-size: 1em;
  color: rgb(0, 0, 0);
  margin-bottom: 20px;
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  background-color: rgba(255, 255, 255, 0); /* Make the footer background transparent */
  padding: 10px;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .App {
    height: 100vh; 
    z-index: -10;/* Allow height to adjust based on content */
    background-color: rgba(255, 255, 255, 0);
  }

  .background-container {
    height: 100%; 
    background-color: rgba(255, 255, 255, 0);
  }

  .content {
    width: 100%; /* Ensure content takes full width */
    padding: 10px; /* Add some padding */
    z-index: 1; /* Ensure content is above the background */
  }

  .icons-bar {
    position: absolute;
    bottom: 0;
    width: 100%; /* Ensure it spans the full width */
    justify-content: center; /* Center the icons */
    flex-wrap: wrap; /* Adjust spacing */
    padding-bottom: 20px;
    z-index: -10;
  }

  .icon {
    margin: 5px; /* Adjust spacing between icons */
  }

  .icon-image {
    width: 50px; /* Adjust size for mobile */
    height: 50px; /* Adjust size for mobile */
  }

  .contract-address {
    position: absolute;
    bottom: 13vh;
    left: 10vw;
    color: rgb(185, 169, 76);
    font-size: 1em;
    background-color: rgb(255, 255, 255);
  }

  .copied-message {
    bottom: 20vh; /* Position the message above the contract address */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(5, 5, 5);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.7em; /* Adjust font size for mobile */
    text-shadow: none; /* Remove text shadow for better readability */
    z-index: 10;
  }

  .App-footer {
    font-size: 0.8em; /* Adjust font size for mobile */
    padding: 5px; /* Adjust padding */
  }
}